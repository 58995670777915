import {
  makeObservable,
  flow,
  observable,
  computed,
  action,
  reaction,
  runInAction,
} from 'mobx'
import {nanoid} from 'nanoid'
import type {CommerceAPI, ShopperCustomers} from 'commerce-api'
import type {RootStore} from './RootStore'
import type {} from 'commerce-api/dist/auth'
import type {OmitFromKnownKeys, StoreModel} from './utils'
import type {PaymentInstrument, Status} from '../types/commerce'
import {getAppOrigin} from '@salesforce/pwa-kit-react-sdk/utils/url'
import {Cookies, LocalStorage} from '../utils/storages'
import {getErrorMessage, objectKeys} from '../utils/utils'
import {BONUS_CARD_PREFIX, COOKIE_STORAGE_KEYS} from '../utils/constants'
import {
  BonusCardDataAndTransactionsDetails,
  BonusCardLinkResult,
  BonusCardTopupFailedResponse,
  BonusCardTopupSuccessResponse
} from '../types/store/customer'
import {OrderTracking} from '../types/store/order'
import {StatusMap} from '../types/commerce'
import {SubmitOrderData} from '../types/store/basket'
import {ArrayElement, NonUndefined, Nullable} from '../types/utils'
import {RegistrationFormFields} from '../types/forms'
import {ProductModel, Promotion} from './ProductStore'
import { CustomerLoginResponse } from 'commerce-api/src/types/auth'


export type CustomerCredentials = Parameters<CommerceAPI['auth']['login']>[0]
export interface Customer
  extends OmitFromKnownKeys<ShopperCustomers.Customer, 'paymentInstruments'> {
  paymentInstruments?: PaymentInstrument[]
  birthday?: string
  addresses?: CustomerAddress[]
}

export type CustomerAddress = ShopperCustomers.CustomerAddress

export type CustomerUpdateProps = {
  firstName?: string
  lastName?: string
  phone?: string
  title?: string
  birthday?: string
  email?: string
  password?: string
  login?: string
  excludeSubstitutes?: boolean
  addresses?: Array<CustomerAddress>
  emailToken?: string
}

export type CustomerWishlist = Omit<
  ShopperCustomers.CustomerProductList,
  'customerProductListItems'
> & {items: NonUndefined<ShopperCustomers.CustomerProductList['customerProductListItems']>}

export type CommunicationPreference = {
  label: string,
  name: string,
  value: boolean
}

export class CustomerStore implements StoreModel {
  rootStore: RootStore
  api: CommerceAPI
  customerInfo: Customer | undefined
  wishlist: Wishlist
  wishlistHits: any
  orders: ShopperCustomers.Order[]
  activeOrders: ShopperCustomers.Order[]
  _status: Status
  addressStatus: any
  ordersPaging: any
  sessionBridged: boolean
  frequentlyBought: string[]
  lastActive: number
  bonusCardDataAndTransactions: Nullable<BonusCardDataAndTransactionsDetails>
  dataProcessingIceMarketing: boolean
  communicationPreferences: CommunicationPreference[]
  verificationData: Nullable<{success: boolean; verificationToken: string}>
  accessTokenExpiry: Date | null
  registrationInProgress: boolean
  emailChangeToken: string
  newEmailAddress: string
  emailChangeComplete: boolean
  twoFactorAuthData: Nullable<{ twoFactorRequired: boolean; success: boolean; twoFactorFailed: boolean; hint: string} > | undefined
  errorMessage : string | null
  private _lastBonusCardPollTime: Nullable<Date>
  currentCustomerGroups: string[]

  constructor(
    rootStore: RootStore,
    initialState = {
      customerInfo: {},
      wishlist: {items: []},
      wishlistHits: [],
      orders: [],
      status: StatusMap.IDLE,
      addressStatus: {},
      frequentlyBought: [],
    }
  ) {
    this.customerInfo = initialState?.customerInfo
    this.wishlist = new Wishlist(initialState?.wishlist)
    this.wishlistHits = initialState?.wishlistHits
    this.orders = initialState?.orders
    this._status = initialState?.status
    this.addressStatus = initialState?.addressStatus
    this.ordersPaging = undefined
    this.sessionBridged = false
    this.frequentlyBought = initialState?.frequentlyBought
    this.lastActive = Date.now()
    this.activeOrders = []
    this.currentCustomerGroups = []
    this.bonusCardDataAndTransactions = null
    this.dataProcessingIceMarketing = false
    this.communicationPreferences = []
    this.verificationData = null
    this.accessTokenExpiry = null
    this.registrationInProgress = false
    this.emailChangeToken = ''
    this.newEmailAddress = ''
    this.emailChangeComplete = false
    this.errorMessage = null
    this._lastBonusCardPollTime = null

    makeObservable(this, {
      customerInfo: observable,
      wishlist: observable,
      wishlistHits: observable,
      orders: observable,
      lastActive: observable,
      ordersPaging: observable,
      sessionBridged: observable,
      frequentlyBought: observable,
      _status: observable,
      activeOrders: observable,
      bonusCardDataAndTransactions: observable,
      currentCustomerGroups: observable,
      dataProcessingIceMarketing: observable,
      communicationPreferences: observable,
      verificationData: observable,
      accessTokenExpiry: observable,
      customerDeliveriesTrackingMapData: computed,
      loading: computed,
      isRegistered: computed,
      isGuest: computed,
      customerGroups: computed,
      deliveryStoreId: computed,
      addresses: computed,
      hasSavedAddresses: computed,
      primaryAddress: computed,
      paymentInstruments: computed,
      hasSavedCards: computed,
      wishlistItems: computed,
      wishlistItemCount: computed,
      frequentlyBoughtProducts: computed,
      bonusCard: computed,
      bonusCardBalance: computed,
      bonusCardLastFour: computed,
      resetVerificationData: action.bound,
      setLastActiveToNow: action.bound,
      setLastActive: action.bound,
      hasAccessTokenExpired: action.bound,
      setCustomerGroups: action.bound,
      getFullBonusCardNumber: action.bound,
      getApplicablePromotions: action.bound,
      excludeSubstitutes: computed,
      getCustomer: flow.bound,
      getCustomerInfo: flow.bound,
      retryGetCustomerInfo: flow.bound,
      getOrCreateWishlist: flow.bound,
      getResetPasswordToken: flow,
      verifyResetPasswordToken: flow.bound,
      resetCustomerPassword: flow.bound,
      loginCustomer: flow.bound,
      logout: flow.bound,
      getCustomerOrders: flow.bound,
      registerCustomer: flow.bound,
      updateCustomer: flow.bound,
      updatePassword: flow.bound,
      createCustomerAddress: flow.bound,
      updateCustomerAddress: flow.bound,
      removeCustomerAddress: flow.bound,
      addCustomerPaymentInstrument: flow.bound,
      deleteCustomerPaymentInstrument: flow.bound,
      addItemToWishlist: flow.bound,
      updateItemInWishlist: flow.bound,
      removeItemFromWishlist: flow.bound,
      clearSoftLogoutWishlist: flow.bound,
      getFrequentlyBought: flow.bound,
      resetSoftLogoutAppState: flow.bound,
      setSoftLogoutAppState: flow.bound,
      automaticallyUpdatePrimaryAddress: flow.bound,
      topupCustomerBonusCard: flow.bound,
      cancelOrder: flow.bound,
      getAllCustomerOrders: flow.bound,
      linkBonusCard: flow.bound,
      unlinkBonusCard: flow.bound,
      getBonusCardDataAndTransactions: flow.bound,
      updateCommunicationPreferences: flow.bound,
      getCommunicationPreferences: flow.bound,
      vaultPaymentCard: flow.bound,
      registrationInProgress: observable,
      emailChangeToken: observable,
      newEmailAddress: observable,
      updateCustomerDetails: flow.bound,
      confirmEmailChangeToken: flow.bound,
      emailChangeComplete: observable,
    })

    this.api = rootStore.api
    this.rootStore = rootStore

    // We need to check if registered customer is having primary address
    reaction(
      () => this.customerInfo?.addresses,
      async () => {
        await this.automaticallyUpdatePrimaryAddress()
      }
    )
  }

  get status() {
    // Proxy access to this.status -> this._status to allow a setter to exist
    return this._status
  }

  set status(status: Status) {
    if (status !== "error"){
      // Clear error message when status updates
      this.errorMessage = null;
    } else {
      // If the status is set to error then display the default message.
      // This can be overridden by directly updating `errorMessage`
      this.errorMessage = "Something's not right, please check and try again."
    }
    this._status = status
  }

  get asJson() {
    return {
      customerInfo: this.customerInfo,
      wishlist: this.wishlist,
      wishlistHits: this.wishlistHits,
      orders: this.orders,
    }
  }

  get isRegistered() {
    return this.customerInfo?.authType === 'registered'
  }

  get isGuest() {
    return this.customerInfo?.authType === 'guest'
  }

  get customerGroups() {
    return this.customerInfo?.c_customerGroups ?? []
  }

  get deliveryStoreId() {
    return this.customerInfo?.c_deliveryStoreId
  }

  get loading() {
    return this.status === 'pending'
  }

  get addresses() {
    return this.customerInfo && this.customerInfo.addresses ? this.customerInfo.addresses : []
  }

  get hasSavedAddresses() {
    return this.addresses && this.addresses.length > 0
  }

  get primaryAddress() {
    return (
      this.customerInfo &&
      this.customerInfo.addresses &&
      this.customerInfo.addresses.find((address) => address.preferred === true)
    )
  }

  get paymentInstruments() {
    return this.customerInfo && this.customerInfo.paymentInstruments
      ? this.customerInfo.paymentInstruments
      : []
  }

  get hasSavedCards() {
    return this.paymentInstruments && this.paymentInstruments?.length > 0
  }

  get wishlistItems() {
    return this?.wishlist?.items || []
  }

  get wishlistItemCount() {
    return this.wishlist.count
  }

  get frequentlyBoughtProducts() {
    const {productsById} = this.rootStore.productStore

    return (this.frequentlyBought ?? []).reduce<ProductModel[]>((acc, id) => {
      const product = productsById[id]
      if (product) {
        acc.push(product)
      }
      return acc
    }, [])
  }

  get excludeSubstitutes() {
    return this.customerInfo?.c_excludeSubstitutes
  }

  get hasLinkedBonusCard() {
    return this.customerInfo?.c_hasLinkedBonusCard
  }

  get bonusCard() {
    return (
      this.paymentInstruments.find(({paymentMethodId}) => paymentMethodId === 'BONUS_CARD') || null
    )
  }

  get bonusCardBalance() {
    return this.bonusCardDataAndTransactions?.cardData?.balance
  }

  get bonusCardLastFour() {
    return this.bonusCardDataAndTransactions?.cardData?.lastFour
  }

  get bonusCardAllowRefresh() {
    // Check if the last contact to the bonus card API was more than 3 seconds ago
    if (!this._lastBonusCardPollTime) {
      return true
    }

    const now = new Date()
    const diff = now.getTime() - this._lastBonusCardPollTime.getTime()

    return diff > 3000
  }

  get customerDeliveriesTrackingMapData() {
    if (!this.activeOrders.length) return []

    const {orderStore} = this.rootStore

    const ordersWithMapAvailable = this.activeOrders.filter((order) =>
      orderStore.isDeliveryTrackingMapDisplayed(order)
    )

    return ordersWithMapAvailable.map((order) => {
      let orderDeliverySlot: {startHour: string; endHour: string} | null = null;

      if (order.c_windowStartTime && order.c_windowEndTime) {
        const startHour = new Date(order.c_windowStartTime);
        const endHour = new Date(order.c_windowEndTime);
        
        orderDeliverySlot = {
          startHour: startHour.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
          endHour: endHour.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
        };
      }
      return {mneticsData: order.c_trackingData as OrderTracking, deliverySlot: orderDeliverySlot}
    })
  }

  resetBonusCardLastPollTime() {
    this._lastBonusCardPollTime = new Date()
  }

  hasAccessTokenExpired() {
    // If access token hasn't yet been set or is in the process of being set return false
    if(this.accessTokenExpiry == null || this.status === 'pending') {
      return false
    }

    const currentDate = new Date()
    const currentTimeMs = currentDate.getTime()
    const expiryTimeMs = this.accessTokenExpiry.getTime()

    return currentTimeMs > expiryTimeMs
  }

  setCustomerGroups(groups?: string[]) {
    this.currentCustomerGroups = groups && groups.length > 0 ? groups : this.customerInfo?.c_customerGroups
    // When we update customer groups, we should trigger getting updated BeforeYouGo Pages as they can be affected by changes in customer groups
    this.rootStore.basketStore.getBeforeYouGoPages(true)
  }

  setLastActive(timestamp: number) {
    this.lastActive = timestamp
  }

  setLastActiveToNow() {
    this.setLastActive(Date.now())
  }

  getFullBonusCardNumber(value: string | undefined) {
    return value ? BONUS_CARD_PREFIX + value.replace(/\s/g, '') : value
  }

  /*
    Get the applicable promotions for a product
  */
  getApplicablePromotions(productPromotions: {multiBuyPromo: Promotion; nearPromos: Promotion[]} | undefined) {
    let allPromos: Promotion[] = []
    let onlineExclusivePromo: Promotion | null = null
    let nearPromo: Promotion | null = null
    if (productPromotions) {
      const applicablePromotions = productPromotions.nearPromos.filter(promo => 
        promo.basedOnCustomerGroups ? 
        promo.basedOnCustomerGroups.some(group => 
            this.currentCustomerGroups.includes(group)
        ) : false
      )
      onlineExclusivePromo = applicablePromotions.filter((promo) => promo.promotionPrice !== undefined)[0] ?? null

      allPromos = applicablePromotions.filter((promo) => promo.promotionPrice === undefined) ?? null

      nearPromo = allPromos && allPromos.length > 0 ? allPromos[0] : null
    }
    return {
      allPromos,
      multiBuyPromo: nearPromo,
      nearPromo,
      onlineExclusivePromo
    }
  }
  /**
   * Get a guest or registered customer token and retrieve their info.
   */
  async *getCustomer(credentials?: CustomerCredentials) {
    this.status = 'pending'

    // Once we are getting registered customer after guest login, we need to set 'usid', that will be passed to /login
    // The 'usid' key will be needed for SCAPI merge/transfer functionality
    if (credentials && this.customerInfo?.authType === 'guest' && this.customerInfo?.usid) {
      credentials.usid = this.customerInfo.usid
    }

    try {
      yield this.api.auth
        .login(credentials)
        .then(async (res) => {
          // Check for Suspension
          if (res?.suspended === true) {
            this.status = 'done'
            if (res?.verificationToken) {
              // Update the Verification Data in this store to trigger Verification Process
              const verificationTokenResponse = {
                verificationToken: res?.verificationToken,
                success: false,
              }
              runInAction(() => {
                this.verificationData = verificationTokenResponse
              })
            }
          }
          if (res?.twoFactorRequired === true){
            // If the login response contains 2FA property set it in the store to show the screen
            const response = {
              success: false,
              twoFactorRequired: res.twoFactorRequired,
              twoFactorFailed: res.twoFactorRequired,
              hint: res.hint || "Your Email",
              authType: 'guest'
            }
            runInAction(() => {
              this.status = 'done'
              this.twoFactorAuthData = response
            })
          }
          this.accessTokenExpiry = res?.access_token_expiry

          // Customer Authenticated
          if (res?.authType === 'registered') {
            const customerId = (res as CustomerLoginResponse)?.customerId
            await this.resetTwoFactorResponse();
            await this.resetSoftLogoutAppState()
            if (res?.basket && Object.keys(res?.basket).length !== 0) {
              await this.rootStore.basketStore.getOrCreateBasket(res?.basket, res?.incompleteOffers, customerId)
            }
            await this.getCustomerInfo(customerId)
            await this.setLastActiveToNow()

            if (res.c_customerGroups) {
              this.setCustomerGroups(res.c_customerGroups)
            }
            this.status = 'done'
          }
          
          // Guest Login
          if (res?.authType !== 'registered') {
            this.customerInfo = res
            this.status = 'done'
          }

        })
        .catch((e) => {
          throw new Error(e)
        })
    } catch (error) {
      const errorMessage = getErrorMessage(error)

      if (errorMessage.startsWith('HTTPError 401') || errorMessage.startsWith('HTTPError 409')) {
        throw new Error(errorMessage)
      }
    }
  }

  *getCustomerOrders(params: {
    organizationId?: string
    customerId?: string
    crossSites?: boolean
    from?: string
    until?: string
    status?: string
    siteId?: string
    offset?: any
    limit?: number
  }) {
    if (this.customerInfo?.authType === 'registered') {
      try {
        this.status = StatusMap.PENDING
        const res: ShopperCustomers.CustomerOrderResult =
          yield this.api.scapiShopperCustomers.getCustomerOrders({
            parameters: {
              customerId: params.customerId || this.customerInfo.customerId!,
              offset: 0,
              limit: 10,
              ...params,
            },
          })

        const {data, ...rest} = res

        this.orders = data
        this.ordersPaging = rest
        this.status = StatusMap.DONE

        return res
      } catch (error) {
        console.error(error)
        this.status = StatusMap.ERROR
      }
    }
  }

  *getAllCustomerOrders() {
    if (!this.isRegistered || typeof this.customerInfo?.customerId === 'undefined' ) return

    // TODO: will require future pagination!
    const MAX_ORDERS_REQ_LIMIT = 10
    let orders = []

    try {
      const ordersResult: ShopperCustomers.CustomerOrderResult =
        yield this.api.scapiShopperCustomers.getCustomerOrders({
          parameters: {
            customerId: this.customerInfo?.customerId,
            offset: 0,
            limit: MAX_ORDERS_REQ_LIMIT,
          },
        })

      const {data, total, ...pagination} = ordersResult

      const offset = pagination.offset
      orders = [...(data ?? [])]
      // This needs to be refactored to be promise based as the await is blocking
      // the page until all the orders are loaded. This just loads 10 orders. 
      if (orders.length) {
      //   while (total !== orders.length) {
      //     const nextOrderResult: ShopperCustomers.CustomerOrderResult =
      //       yield this.api.scapiShopperCustomers.getCustomerOrders({
      //         parameters: {
      //           customerId: this.customerInfo?.customerId,
      //           offset: orders.length,
      //           limit: MAX_ORDERS_REQ_LIMIT,
      //         },
      //       })
      //     const {data: nextData, ...nextPagination} = nextOrderResult
      //     offset = nextPagination.offset
      //     orders = [...orders, ...nextData]
      //   }

        const activeOrders: ShopperCustomers.Order[] =
          yield this.rootStore.orderStore.getActiveOrders(orders)

        runInAction(() => (this.activeOrders = activeOrders))
      }

      this.orders = orders
      this.status = StatusMap.DONE
      this.ordersPaging = {offset, total}
    } catch (error) {
      console.error(error)
      this.status = StatusMap.ERROR
    }
  }

  *retryGetCustomerInfo(customerId: string) {
    try {
      const result: Response = yield fetch(`${getAppOrigin()}/mobify/proxy/ocapi/on/demandware.store/Sites-icelandfoodsuk-Site/default/Customer-FixCustomerAddresses`,{method: 'POST'})
      const json: {success:boolean} = yield result.json();
      if (json.success) {
        const retryResult: Customer = yield this.api.scapiShopperCustomers.getCustomer({
          parameters: {customerId: customerId},
        })

        if(!retryResult.customerId) {
          throw Error
        }

        this.customerInfo = retryResult

        this.getOrCreateWishlist()
      }
      this.status = 'done'
    } catch (error) {
      console.error('Retry get customer info failed', error)
      this.status = StatusMap.ERROR
    }
  }

  *getCustomerInfo(customerId: string) {
    this.status = 'pending'
    try {
      const result: Customer = yield this.api.scapiShopperCustomers.getCustomer({
        parameters: {customerId: customerId},
      })

      // If the customer isn't retrieved 
      // or it somehow manages to get the customer but it still has broken addresses attempt to fix
      const anyBrokenAddresses = result.addresses && result.addresses.filter((address) => !address.addressId).length > 0
      if(!result.customerId || anyBrokenAddresses) {
        this.retryGetCustomerInfo(customerId)
      }

      this.customerInfo = result

      this.getOrCreateWishlist()

      this.status = 'done'
    } catch (error) {
      console.error('Get customer info failed', error)
      this.status = StatusMap.ERROR
    }
  }

  *getOrCreateWishlist(custId?: string) {
    const customerId = this.customerInfo?.customerId || custId

    if (!customerId) {
      return
    }

    try {
      const wishlists: ShopperCustomers.CustomerProductListResult =
        yield this.api.scapiShopperCustomers.getCustomerProductLists({
          parameters: {
            customerId: customerId,
          },
        })

      const [existedWishlist] = wishlists?.data ?? []

      if (!existedWishlist) {
        const newCustomerWishlist: ShopperCustomers.CustomerProductList =
          yield this.api.scapiShopperCustomers.createCustomerProductList({
            body: {
              type: 'wish_list',
            },
            parameters: {
              customerId: customerId,
            },
          })

        const normalizedNewWishlist = normalizeCustomerWishlistResponse(newCustomerWishlist)
        if (normalizedNewWishlist) this.wishlist = new Wishlist(normalizedNewWishlist)
        this.updateWishListHits()

        return
      }

      const normalizedWishlist = normalizeCustomerWishlistResponse(existedWishlist)

      if (normalizedWishlist) {
        this.wishlist = new Wishlist(normalizedWishlist)
        if (normalizedWishlist.items?.length) {
          const productIds = normalizedWishlist.items
            .map((item) => item.productId)
            .filter((id): id is string => Boolean(id))
            //ensure products in wishlist are in the product store
            yield this.rootStore.productStore.fetchProducts(productIds, false) 
            this.updateWishListHits()
          }
      }
      } catch (error) {
      console.error(error)
    }
  }

  *getResetPasswordToken(login: string) {
    // eslint-disable-next-line no-useless-catch
    try {
      const response: ShopperCustomers.ResetPasswordToken =
        yield this.api.scapiShopperCustomers.getResetPasswordToken({body: {login}})
      // Check for error json response
      if (response.detail && response.title && response.type) {
        throw new Error(response.detail)
      }
    } catch (error: any) {
      throw error
    }
  }

  *verifyResetPasswordToken(token: string) {
    const response: Response = yield fetch(
      `${getAppOrigin()}/mobify/proxy/ocapi/on/demandware.store/Sites-icelandfoodsuk-Site/default/Account-VerifyResetPasswordToken`,
      {
        method: 'POST',
        body: JSON.stringify({
          token: token,
        }),
      }
    )

    const res: {success: boolean} = yield response.json()

    return res
  }

  *resetCustomerPassword(token: string, password: string) {
    if (token === '') {
      throw new Error('No token provided')
    }

    const response: Response = yield fetch(
      `${getAppOrigin()}/mobify/proxy/ocapi/on/demandware.store/Sites-icelandfoodsuk-Site/default/Account-SetNewCustomerPassword`,
      {
        method: 'POST',
        body: JSON.stringify({
          token: token,
          password: password,
        }),
      }
    )

    const res: {success: boolean} = yield response.json()

    return res.success
  }

  *loginCustomer(credentials: CustomerCredentials) {
    try {
      yield this.getCustomer(credentials)

      this.rootStore.orderStore.recentOrder = null
      this.orders = []
    } catch (error: any) {
      throw new Error(error)
    }
  }

  *logout() {
    // Saving registered customer basket for soft log out
    this.setSoftLogoutAppState()

    this.rootStore.orderStore.recentOrder = null
    this.resetTwoFactorResponse();

    const result: CustomerLoginResponse = yield this.api.auth.logout()

    this.sessionBridged = false
    if (result?.basket && Object.keys(result?.basket).length !== 0) {
      this.rootStore.basketStore.getOrCreateBasket(result.basket)
    }
    this.customerInfo = result
    this.setCustomerGroups(result.c_customerGroups)
  }

  *setSoftLogoutAppState() {
    const postCode = this.rootStore.basketStore.deliveryPostalCode

    if (postCode) {
      LocalStorage.set(
        COOKIE_STORAGE_KEYS.REGISTERED_CUSTOMER_SOFT_LOGOUT_POSTCODE,
        this.rootStore.basketStore.deliveryPostalCode
      )
    }

    Cookies.set(COOKIE_STORAGE_KEYS.REGISTERED_CUSTOMER_SOFT_LOGOUT, 'true', {expires: 31})

    const customerDetailsStringify = JSON.stringify({
      email: this.customerInfo?.email,
      firstName: this.customerInfo?.firstName,
      customerId: this.customerInfo?.customerId,
      login: this.customerInfo?.login,
      customerNo: this.customerInfo?.customerNo,
      lastName: this.customerInfo?.lastName,
      addressBook: this.customerInfo?.addresses,
      orderCount: this.customerInfo?.c_ordersCount,
      legacyOrderCount: this.customerInfo?.c_legacyOrderCount
    })

    LocalStorage.set(
      COOKIE_STORAGE_KEYS.REGISTERED_CUSTOMER_SOFT_LOGOUT_ACCOUNT_DETAILS,
      customerDetailsStringify
    )
    yield null;
  }

  *resetSoftLogoutAppState() {
    if (Cookies.get(COOKIE_STORAGE_KEYS.REGISTERED_CUSTOMER_SOFT_LOGOUT) === 'true') {
      Cookies.set(COOKIE_STORAGE_KEYS.REGISTERED_CUSTOMER_SOFT_LOGOUT, 'false', {expires: 31});
    }
    Cookies.remove(COOKIE_STORAGE_KEYS.REGISTERED_CUSTOMER_SOFT_LOGOUT_ACCOUNT_DETAILS);
    yield null;
  }

  resetTwoFactorResponse() {
    runInAction(() => {
      this.twoFactorAuthData = null;
    });
  }

  *registerCustomer(data: RegistrationFormFields, isTwoFactorEnabled: boolean) {
      const body = {
        password: data.password,
        customer: {
          login: data.email,
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          title: data.title,
          addresses: [
            {
              phone: data.phone,
              address1: data.address1,
              address2: data.address2,
              city: data.city,
              stateCode: data.stateCode,
              addressId: data.addressId,
              postalCode: data.postalCode,
              title: data.title,
              firstName: data.firstName,
              lastName: data.lastName,
              // Hardcoded country code as we do not ask for country in registration form but is required
              countryCode: 'GB',
            },
          ],
          c_isDigitalRequest: data.receiveBonusCard,
          c_bonusCardNumber: this.getFullBonusCardNumber(data.bonusCardNumber),
          c_registrationSource: data.deviceCode,
          c_acceptsMarketing: data.acceptsMarketing
        },
      }
    yield this.api.auth.registerCustomer(body, isTwoFactorEnabled)
      
    yield this.getCustomer({email: data.email, password: data.password})
  }

  async *updateCustomer(data: CustomerUpdateProps) {
    if(data.password){
      try {
        const result: CustomerLoginResponse = yield this.api.auth.login({
          email: this.customerInfo?.email || '',
          password: data.password || '',
        })

        this.customerInfo = result
        this.setCustomerGroups(result.c_customerGroups)
        
      } catch (error) {
        throw new Error('Does not match the current password')
      }
    }

    this.status = 'pending'

    try {
      const body = {
        firstName: data.firstName,
        lastName: data.lastName,
        phoneHome: data.phone,
        title: data.title,
        birthday: data.birthday ? data.birthday : null,
        // NOTE/ISSUE
        // The sdk is allowing you to change your email to an already-existing email.
        // I would expect an error. We also want to keep the email and login the same
        // for the customer, but the sdk isn't changing the login when we submit an
        // updated email. This will lead to issues where you change your email but end
        // up not being able to login since 'login' will no longer match the email.
        email: data.email,
        login: data.email,
        c_emailToken: data.emailToken,
        c_password: data.password,
        c_excludeSubstitutes: data.excludeSubstitutes,
        ...(data.addresses ? {addresses: data.addresses} : {}),
      }
      const customerId = this.customerInfo!.customerId!
      const result: Customer = yield this.api.shopperCustomers.updateCustomer({
        body,
        parameters: {customerId},
      })
      
      this.customerInfo = {...this.customerInfo, ...result}
      this.status = 'done'
      return result
    } catch (error) {
      this.status = 'error'
      throw error
    }
  }

  *updatePassword(data: ShopperCustomers.PasswordChangeRequest) {
    this.status = 'pending'
    const body = {
      password: data.password,
      currentPassword: data.currentPassword,
    }
    try {
      const result : {status: number} = yield this.api.scapiShopperCustomers.updateCustomerPassword(
        {
          body,
          parameters: {
            customerId: this.customerInfo!.customerId!,
            siteId: this.rootStore.globalStore.siteId,
            organizationId: this.rootStore.globalStore.organizationId,
          },
        },
        true
      )
      if (result.status !== 204){
        throw new Error(`Password Change Operation Failed: Status ${result.status}`)
      }
      this.status = 'done'
      return result
    } catch (error) {
      this.status = 'error'
      throw error
    }
  }

  *createCustomerAddress(address: CustomerAddress) {
    const body = {
      ...address,
      addressId: address.addressId || nanoid(),
    }
    try {
      this.status = 'pending'
      const result: CustomerAddress = yield this.api.shopperCustomers.createCustomerAddress({
        body,
        parameters: {customerId: this.customerInfo!.customerId!},
      })

      if (this.customerInfo!.addresses) {
        this.customerInfo!.addresses.push(result)
      } else {
        this.customerInfo!.addresses = [result]
      }
      this.status = 'done'

      return result
    } catch (error) {
      this.status = 'error'
      throw error
    }
  }

  *updateCommunicationPreferences(prefName: string, value: boolean) {
    try {
      const res: Response = yield fetch(
        `${getAppOrigin()}/mobify/proxy/ocapi/on/demandware.store/Sites-icelandfoodsuk-Site/default/Account-SetCommunicationPreferences?${prefName}=${
          value
        }`,
        {
          method: 'POST',
          credentials: 'include',
        }
      )
  
      if (!res.ok) {
        throw new Error('Error during setting communication preferences');
      }
  
      const json: {error: boolean}  =  yield res.json();
      return json
    } catch (error) {
      console.error(error, "error");
      return { error: error.message };
    }
  }

  *getCommunicationPreferences() {
    try {
      const res: Response = yield fetch(
        `${getAppOrigin()}/mobify/proxy/ocapi/on/demandware.store/Sites-icelandfoodsuk-Site/default/Account-CommunicationPreferences`,
        {
          credentials: 'include',
        }
      )

      if (!res.ok) {
        throw new Error('Error during getting communication preferences')
      }
      const json:{result: CommunicationPreference[]} =  yield res.json()
      this.communicationPreferences = json.result
      return json.result
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * Check booked slot is linked to updated/deleted address
   * @param addressId Id of address being updated
   * @returns True if the slot needs to be voided
   */
  checkSlotAgainstAddress(addressId: string) {
    const address = this.customerInfo!.addresses?.find((address) => address.addressId === addressId)
    return address?.postalCode === this.rootStore.basketStore?.deliverySlotPostalCode
  }

  /**
   * Reset the current postcode that is set if the a slot is booked against an address that has been updated/deleted
   * @param isBookedSlotLinkedToAddress True if the slot needs to be voided
   */
  voidSlotAgainstAddress(isBookedSlotLinkedToAddress: boolean) {
    if (isBookedSlotLinkedToAddress) {
      const preferredAddress = this.customerInfo!.addresses?.find((address) => address.preferred)
      const preferredPostCode = preferredAddress?.postalCode
      if (preferredPostCode) {
        this.rootStore.basketStore.setPostCode(preferredPostCode)
      }
    }
  }

    /**
     * 
     * @param newAddress The updated address
     * @param currentAddressId (Optional) Use this if the address ID is going to be 
     *  updated. This param should be the current ID and include the new ID in the `newAddress` param
     */
  *updateCustomerAddress(newAddress: CustomerAddress, currentAddressId: string | null = null) {
    this.status = 'pending'
    const isBookedSlotLinkedToAddress = this.checkSlotAgainstAddress(currentAddressId)
    const body = {...newAddress, countryCode: newAddress.countryCode?.toUpperCase()}
    try {
      const result: CustomerAddress = yield this.api.shopperCustomers.updateCustomerAddress({
        body,
        parameters: {
          customerId: this.customerInfo!.customerId!,
          addressName: encodeURIComponent(currentAddressId ? currentAddressId : newAddress.addressId),
        },
      })

      this.customerInfo!.addresses =
        this.customerInfo?.addresses?.filter(
          (address) => address.addressId !== newAddress.addressId
        ) || []
      if (currentAddressId !== null && currentAddressId !== newAddress.addressId ){ 
        // If the operation was successful, remove the old addressID from the list.
        this.customerInfo!.addresses.splice(this.customerInfo!.addresses.findIndex((address) => {
          address.addressId === currentAddressId
        }))
      }
      this.customerInfo!.addresses.push(result)

      // Once we have another address as preferred and updated one is primary now, we need to make others addresses non-primary
      if ((this.customerInfo?.addresses?.length || 0) > 1 && newAddress.preferred) {
        this.customerInfo!.addresses = this.customerInfo?.addresses?.map((address) => {
          if (address.addressId !== newAddress.addressId && address.preferred) {
            address.preferred = false
          }

          return address
        })
      }

      this.voidSlotAgainstAddress(isBookedSlotLinkedToAddress)

      this.customerInfo = {...this.customerInfo, ...result}
      this.status = 'done'
      return result
    } catch (error) {
      console.error('Create customer address failed')
      console.error(error)
      this.status = 'error'
    }
  }

  *removeCustomerAddress(addressId: string) {
    const isBookedSlotLinkedToAddress = this.checkSlotAgainstAddress(addressId)
    
    try {
      const result: void = yield this.api.shopperCustomers.removeCustomerAddress(
        {
          parameters: {customerId: this.customerInfo!.customerId!, addressName: addressId},
        },
        true
      )
      // optimistically filter out the addresses
      this.customerInfo!.addresses =
      this.customerInfo?.addresses?.filter((address) => address.addressId !== addressId) || []
      this.voidSlotAgainstAddress(isBookedSlotLinkedToAddress)
      this.status = 'done'
      return result
    } catch (error) {
      this.status = 'error'
      throw error
    }
  }

  *automaticallyUpdatePrimaryAddress() {
    if (!this.addresses?.length || !this.isRegistered) return

    if (this.addresses?.every((address) => !address.preferred)) {
      const [preferred, ...restAddresses] = this.addresses
      try {
        const newPreferred = {...preferred, preferred: true}
        const result: Customer = yield this.updateCustomer({
          addresses: [newPreferred, ...restAddresses],
        })

        if (result?.customerId && this.customerInfo) {
          this.customerInfo.addresses = [newPreferred, ...restAddresses]

          yield this.rootStore.basketStore.addOrUpdateShippingAddress(newPreferred)
        }
      } catch (e) {
        console.error(e, 'Error during automatically primary address update of customer addresses')
      }
    }
  }

  *addCustomerPaymentInstrument(
    paymentInstrument: ShopperCustomers.CustomerPaymentInstrumentRequest
  ) {
    this.status = 'pending'
    const body = {
      ...paymentInstrument,
      bankRoutingNumber: paymentInstrument.bankRoutingNumber || '',
      giftCertificateCode: paymentInstrument.giftCertificateCode || '',
      paymentCard: {
        ...paymentInstrument.paymentCard,
        securityCode: undefined,
      },
    }

    try {
      const result: ShopperCustomers.CustomerPaymentInstrument =
        yield this.api.scapiShopperCustomers.createCustomerPaymentInstrument({
          body,
          parameters: {customerId: this.customerInfo!.customerId!},
        })
      this.customerInfo!.paymentInstruments = [
        ...this.paymentInstruments,
        result as PaymentInstrument,
      ]
      this.status = 'done'
      return result
    } catch (error) {
      this.status = 'error'
      throw error
    }
  }

  /**
   * Deletes a customer's payment instrument
   * Api endpoint takes the customerId and paymentInstrumentId
   * Does not return a body
   */
  *deleteCustomerPaymentInstrument(paymentInstrumentId: string) {
    this.status = 'pending'
    // optimistically filter out the paymentInstrument
    this.customerInfo!.paymentInstruments =
      this.customerInfo!.paymentInstruments?.filter(
        (instrument) => instrument.paymentInstrumentId !== paymentInstrumentId
      ) || []
    try {
      yield this.api.scapiShopperCustomers.deleteCustomerPaymentInstrument(
        {
          parameters: {customerId: this.customerInfo!.customerId!, paymentInstrumentId},
        },
        true
      )
      this.status = 'done'
    } catch (error) {
      this.status = 'error'
      throw error
    }
  }

  *addItemToWishlist({productId, title, link, quantity = 1, ...variationValues}: WishlistShape) {
    this.wishlist.status = 'adding'
    const item = {productId, priority: 1, public: false, quantity: 1, type: 'product'}

    if (!this.customerInfo?.customerId) {
      return
    }

    // we may not have the wishlist yet if this was a guest user clicking wishlist item then logging in
    if (!this.wishlist?.id) {
      yield this.getOrCreateWishlist()
    }

    // optimistically add item
    if (this.wishlist.items?.length) {
      this.wishlist.items = [...this.wishlist.items, item]
    } else {
      this.wishlist.items = [item]
    }

    try {
      const result: ShopperCustomers.CustomerProductListItem =
        yield this.api.scapiShopperCustomers.createCustomerProductListItem({
          body: {
            productId,
            quantity,
            public: false,
            priority: 1,
            type: 'product',
            ...variationValues,
          },
          parameters: {
            customerId: this.customerInfo.customerId,
            listId: this.wishlist.id!,
          },
        })

      this.wishlist.items = this.wishlist.items.map((item) => {
        if (!item.id && item.productId === productId) {
          return result
        } else {
          return item
        }
      })
      // Make sure we have the added product data in the store (for when adding variants to wishlist)
      this.rootStore.productStore.fetchProducts([productId], false)
      this.updateWishListHits()
      this.wishlist.status = 'idle'
    } catch (error) {
      console.log(error)
      this.wishlist.status = 'error'
    }
  }

  *updateItemInWishlist({itemId, quantity}: {itemId: string; quantity: number}) {
    this.wishlist.status = 'updating'
    if (!this.customerInfo?.customerId) {
      return
    }

    try {
      const result: ShopperCustomers.CustomerProductListItem =
        yield this.api.scapiShopperCustomers.updateCustomerProductListItem({
          body: {
            id: itemId,
            quantity,
            public: false,
            priority: 1,
          },
          parameters: {
            customerId: this.customerInfo.customerId,
            listId: this.wishlist.id!,
            itemId,
          },
        })
      this.wishlist.items = this.wishlist.items.map((item) => {
        if (itemId === item.id) {
          return result
        } else {
          return item
        }
      })
      this.updateWishListHits()
      this.wishlist.status = 'idle'
    } catch (error) {
      console.error('ERROR UPDATING WISHLIST ITEM')
      console.error(error)
      this.wishlist.status = 'error'
    }
  }

  *removeItemFromWishlist(productId: string) {
    if (!this.customerInfo?.customerId) {
      return
    }

    const itemId = this.wishlist.items.find((item) => item.productId === productId)?.id

    if (!itemId) return

    // optimistically remove
    this.wishlist.items = this.wishlist.items.filter((item) => item.id !== itemId)

    try {
      yield this.api.scapiShopperCustomers.deleteCustomerProductListItem(
        {
          parameters: {
            itemId,
            listId: this.wishlist.id!,
            customerId: this.customerInfo.customerId,
          },
        },
        true
      )
      this.updateWishListHits()
    } catch (error) {
      console.log(error)
    }
  }

  *clearSoftLogoutWishlist() {
    this.wishlist = new Wishlist({items: []})
    this.wishlistHits = []
  }

  *getFrequentlyBought() {
    try {
      if (!this.frequentlyBought) {
        const res: Response = yield fetch(
          `${getAppOrigin()}/mobify/proxy/ocapi/on/demandware.store/Sites-icelandfoodsuk-Site/default/Recommendation-FrequentlyBoughtProducts`,
          {credentials: 'include'}
        )
        const json: {products?: []} = yield res.json()
        if (json.products) {
          this.frequentlyBought = json.products
        }
      }
      if (this.frequentlyBought?.length > 0) {
        this.rootStore.productStore.fetchProducts(this.frequentlyBought, false)
      }
    } catch (error) {
      this.status = 'error'
      throw error
    }
  }

  *linkBonusCard(cardNumber = '') {
    try {
      const queryParams = cardNumber.length ? `&cardNumber=${this.getFullBonusCardNumber(cardNumber)}` : ''
      const res: Response = yield fetch(
        `${getAppOrigin()}/mobify/proxy/ocapi/on/demandware.store/Sites-icelandfoodsuk-Site/default/BonusCard-LinkCard?isDigital=${!cardNumber.length}${queryParams}`,
        {
          credentials: 'include',
        },
      )

      const json: BonusCardLinkResult = yield res.json()
      if (this.customerInfo?.customerId){
        // Update the Bonus Card PIs
        this.getCustomerInfo(this.customerInfo.customerId)
      }
      return json
    } catch (error) {
      console.error(error)
    }
  }

  *unlinkBonusCard() {
    try {
      const res: Response = yield fetch(
        `${getAppOrigin()}/mobify/proxy/ocapi/on/demandware.store/Sites-icelandfoodsuk-Site/default/BonusCard-UnlinkCard`,
        {
          credentials: 'include',
        }
      ) 

      const json: {success:boolean} = yield res.json()

      if (json.success) {
        this.bonusCardDataAndTransactions = null
      }
    } catch (error) {
      console.error(error)
    }
  }

  *getBonusCardDataAndTransactions(loadTransactions = true) {
    try {
      if (this.bonusCard === null || !this.bonusCardAllowRefresh) {
        // If there is no bonus card linked or we called the service in the last 3 seconds
        // Then don't call the service.
        yield null;
      } else {
        const res: Response = yield fetch(
          `${getAppOrigin()}/mobify/proxy/ocapi/on/demandware.store/Sites-icelandfoodsuk-Site/default/BonusCard-GetCardData?transactions=${loadTransactions.toString()}`
        )

        if (!res.ok) {
          throw new Error(`Error getting bonus card data. Status ${res.status}`, {cause: res.status})
        }
        const parsed: BonusCardDataAndTransactionsDetails = yield res.json()
        this.bonusCardDataAndTransactions = parsed
        this.resetBonusCardLastPollTime();
      }
    } catch (error) {
      console.error(error)
      this.bonusCardDataAndTransactions = {
        error: error?.message,
        code: error?.cause
      }
    }
  }

  async *confirmEmailChangeToken() {
    const defaultErrorMsg = 'Error confirming email token'
    try {
      const queryString = location.search
      const searchParams = new URLSearchParams(queryString)

      if (searchParams.get('EmailToken') != null) {
        this.emailChangeToken = searchParams.get('EmailToken') || ''

        window.history.replaceState(window.history.state, '', '/account/edit-profile')

        const res: Response = yield fetch(
          `${getAppOrigin()}/mobify/proxy/ocapi/on/demandware.store/Sites-icelandfoodsuk-Site/default/Customer-ConfirmChangeEmailToken?token=${
            this.emailChangeToken
          }`,
          {
            credentials: 'include',
          }
        )

        if (!res.ok) {
          throw new Error(defaultErrorMsg)
        }

        const json: {newEmail?: ''} = yield res.json()

        if (json?.newEmail) {
          this.newEmailAddress = json.newEmail
        } else {
          throw new Error(defaultErrorMsg)
        }

        return true
      }
    } catch (error) {
      this.emailChangeComplete = false
      throw new Error(defaultErrorMsg)
    }
  }

  async *updateCustomerDetails(data: CustomerUpdateProps) {
    try {
      const res = await this.updateCustomer(data)
      if (this.newEmailAddress && this.emailChangeToken) {
        this.emailChangeComplete = true
        this.emailChangeToken = ''
        this.newEmailAddress = ''
      }
      return true
    } catch (error) {
      this.emailChangeComplete = false
      throw new Error(error.message)
    }
    return false
  }

  *topupCustomerBonusCard(
    brainTreeVerificationResult: SubmitOrderData,
    amount: number,
    paymentType: string
  ) {
    const {btData, userData, threeDSecureData, saveCreditCard} =
      brainTreeVerificationResult

    try {
      const body = {
        dwfrm_billing_save: false,
        dwfrm_billing_addressList: '',
        dwfrm_billing_billingAddress_addressFields_customerAddressID: '',
        dwfrm_billing_billingAddress_addressFields_title: this.primaryAddress?.title,
        dwfrm_billing_billingAddress_addressFields_firstName: this.primaryAddress?.firstName,
        dwfrm_billing_billingAddress_addressFields_lastName: this.primaryAddress?.lastName || '',
        dwfrm_billing_billingAddress_addressFields_postal: this.primaryAddress?.postalCode,
        dwfrm_billing_billingAddress_addressFields_address1: this.primaryAddress?.address1,
        dwfrm_billing_billingAddress_addressFields_address2: this.primaryAddress?.address2,
        dwfrm_billing_billingAddress_addressFields_city: this.primaryAddress?.city,
        dwfrm_billing_billingAddress_addressFields_county: this.primaryAddress?.stateCode,
        dwfrm_billing_billingAddress_addressFields_phone: this.primaryAddress?.phone,
        dwfrm_billing_billingAddress_email_emailAddress: this.customerInfo?.email || '',
        dwfrm_billing_billingAddress_addressFields_country: 'gb',
        topUpAmount: amount,
        dwfrm_billing_paymentMethods_selectedPaymentMethodID: paymentType,
        dwfrm_billing_paymentMethods_creditCardList: '',
        dwfrm_creditcard_owner: '',
        dwfrm_creditcard_type: 'Unknown',
        dwfrm_creditcard_number: '************',
        braintreePaymentMethodNonce: threeDSecureData?.nonce || btData?.nonce || '',
        braintreePaypalNonce: threeDSecureData?.nonce || btData?.nonce || '',
        braintreeDeviceData: userData || {},
        braintreeSaveCreditCard: saveCreditCard ?? false,
        dwfrm_billing_paymentMethods_creditCard_type: btData?.details?.cardType || '',
        dwfrm_billing_paymentMethods_creditCard_number: '',
        dwfrm_billing_paymentMethods_creditCard_owner: '',
        'cc-expiration-date': '',
        dwfrm_billing_paymentMethods_creditCard_expiration_month: '',
        dwfrm_billing_paymentMethods_creditCard_expiration_year: '',
        dwfrm_billing_paymentMethods_creditCard_cvn: ''
      }

      const formBody: string[] = []

      for (const property of objectKeys(body)) {
        if (typeof body[property] !== 'object') {
          const encodedKey = encodeURIComponent(property)
          const encodedValue = encodeURIComponent(body[property] as string)
          formBody.push(encodedKey + '=' + encodedValue)
        }
      }

      const res: Response = yield fetch(
        `${getAppOrigin()}/mobify/proxy/ocapi/on/demandware.store/Sites-icelandfoodsuk-Site/default/BonusCard-TopUpCard`,
        {
          method: 'POST',
          headers: {'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'},
          body: formBody.join('&'),
        }
      )

      if (!res.ok) {
        const failed: BonusCardTopupFailedResponse = yield res.json()
        throw failed
      }

      const json: BonusCardTopupSuccessResponse = yield res.json()

      return json
    } catch (error) {
      let result: BonusCardTopupFailedResponse = {success: false, reason: 'TOP_UP_ERROR'}

      if ((error as BonusCardTopupFailedResponse).reason) {
        result = error as BonusCardTopupFailedResponse
      }

      return result
    }
  }

  *cancelOrder(orderNo: string) {
    try {
      const res: Response = yield fetch(
        `${getAppOrigin()}/mobify/proxy/ocapi/on/demandware.store/Sites-icelandfoodsuk-Site/default/DeliveryOrder-CancelOrder?&orderid=${orderNo}`,
        {
          method: 'POST',
          credentials: 'include',
        }
      )

      const json: {success?: boolean} = yield res.json()

      if (!res.ok || !json?.success) {
        throw new Error('Error during order cancellation: ' + orderNo)
      } else {
        const orderIndex = this.orders.findIndex((order) => order.orderNo === orderNo)

        if (orderIndex !== -1) {
          const orderItem = this.orders[orderIndex]
          this.orders[orderIndex] = {
            ...orderItem,
            c_data: {...(orderItem?.c_data || {}), fulfilment_status: 'Cancelled'},
          }

          this.orders = [...this.orders]

          const activeOrders: ShopperCustomers.Order[] =
            yield this.rootStore.orderStore.getActiveOrders(this.orders)

          this.rootStore.orderStore.recentOrder = null

          runInAction(() => (this.activeOrders = activeOrders))
        }
      }
    } catch (error) {
      throw new Error('Error during order cancellation: ' + orderNo)
    }
  }

  *vaultPaymentCard(nonce: string | undefined) {
    try {
      const response: Response = yield fetch(
        `${getAppOrigin()}/mobify/proxy/ocapi/on/demandware.store/Sites-icelandfoodsuk-Site/default/Account-VaultCreditCard?nonce=${nonce}`,
        {
          method: 'POST',
        }
      )

      const json: {success?: boolean} = yield response.json()

      // After successfully vaulting a card check if the stored customer has a braintree customer id if not get the updated customer
      if (
        json.success &&
        this.isRegistered &&
        !this.customerInfo?.c_braintreeCustomerId &&
        this.customerInfo?.customerId
      ) {
        this.getCustomerInfo(this.customerInfo.customerId)
      }

      return json
    } catch (error) {
      return {success: false}
    }
  }

  resetVerificationData() {
    this.verificationData = null
    if (this.customerInfo) delete this.customerInfo['c_suspensionReason']
  }
  
  updateWishListHits() {
    const {productsById} = this.rootStore.productStore
    
    // Push the Hits into the wishlistHits
    this.wishlistHits = this.wishlistItems
    .map((wishlistItem) => {
      return wishlistItem.productId && productsById[wishlistItem.productId]
        ? productsById[wishlistItem.productId]
        : null
    })
    .filter((item): item is ProductModel => Boolean(item))
  }
}

interface WishlistShape extends Record<string, unknown> {
  productId: string
  title?: string
  quantity?: number
}

class Wishlist {
  id: string | undefined
  items: CustomerWishlist['items']
  status: string

  constructor(wishlist: CustomerWishlist) {
    this.items = []
    this.status = StatusMap.IDLE
    Object.assign(this, wishlist)

    makeObservable(this, {
      items: observable,
      status: observable,
      count: computed,
      loaded: computed,
    })
  }

  get count() {
    return this.items.length
  }

  get loaded() {
    return !!this.id
  }
}

const normalizeCustomerWishlistResponse = (
  wishlistResponse: ArrayElement<ShopperCustomers.CustomerProductListResult['data']>
) => {
  if (!wishlistResponse) return null

  const {customerProductListItems, ...wishlist} = wishlistResponse

  return {
    ...wishlist,
    items: customerProductListItems ?? [],
  }
}

const parseLoginSCAPIErrorToJSON = async (errorMessage: string | null) => {
  let json: Nullable<{fault: {arguments: Record<string, any>}}> = null

  if (errorMessage) {
    try {
      json = await JSON.parse(errorMessage)
    } catch (_) {
      json = null
    }
  }

  return json
}
