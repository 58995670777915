import React, {useEffect, useState} from 'react'
import {getAppOrigin} from '@salesforce/pwa-kit-react-sdk/utils/url'
import {observer} from 'mobx-react-lite'
import {Link} from '../link'
import {Box, Text, Stack, Button, BaseButton} from '../../vanilla'
import {Field} from '../field'
import {useForm} from 'react-hook-form'
import {useGlobalStore} from '../../store/hooks/useStore'
import {GoogleRecaptcha} from '../grecaptcha'
import {getMobileAppOS} from '../../utils/utils'
import {useResetPasswordFields} from '../../hooks/forms/useResetPasswordFields'

interface ResetPasswordFormValues {
  onBack: () => void
  onClose: () => void
}

const ResetPasswordContainer = (props: ResetPasswordFormValues) => {
  const {onBack, onClose} = props
  const [mode, setMode] = useState<'initial' | 'success'>('initial')
  const [status, setStatus] = useState<'idle' | 'loading' | 'success' | 'error'>('idle')
  const form = useForm<{ email: string }>()
  const fields = useResetPasswordFields({form})
  const [captchaValidationSuccess, setCaptchaValidationSuccess] = useState(false)
  const [recaptchaEnabled, setRecaptchaEnabled] = useState(false)
  const [prefLoaded, setPrefLoaded] = useState(false)
  const [isMobileApp, setIsMobileApp] = useState(false)
  const [captchaResponse, setCaptchaResponse] = useState<string | undefined>()
  const {getCustomSitePreferenceById, isCustomSitePreferenceSet, customSitePreferences} =
    useGlobalStore()

  useEffect(() => {
    if (status == "success"){
      if (getMobileAppOS()) {
        setIsMobileApp(true)
      }
  }
  }, [status])

  useEffect(() => {
    (async function () {
      if (!isCustomSitePreferenceSet('reCaptchaEnablePasswordReset')) {
        await getCustomSitePreferenceById('reCaptchaEnablePasswordReset')
      }
      setRecaptchaEnabled(customSitePreferences['reCaptchaEnablePasswordReset'] as boolean)
      setPrefLoaded(true)
    })()
  })


  const handleSubmit = async ({email}: {email: string}) => {
    const formData = new FormData()
    formData.append('dwfrm_requestpassword_email', email)
    formData.append('dwfrm_requestpassword_send', 'true')
    formData.append('dwfrm_requestpassword_recaptcha', captchaResponse ?? '')
    formData.append('format', 'ajax')
    setStatus('loading')
    try {
      const response: Response = await fetch(
        `${getAppOrigin()}/mobify/proxy/ocapi/on/demandware.store/Sites-icelandfoodsuk-Site/default/Account-PasswordResetForm`,
        {
          method: 'POST',
          body: formData,
        }
      )

      const data = await response.json()
      if (data.code === 'success') {
        setStatus('success')
        setMode('success')
      } else {
        setStatus('error')
        form.setError('email', {type: 'validate', message: 'Something went wrong'})
      }
    } catch (err) {
      setStatus('error')
      form.setError('email', {type: 'validate', message: (err as Error).message})
    }
  }
  return (
    <Box padding="32px" paddingTop="16px">
      <Box as="form" onSubmit={form.handleSubmit(handleSubmit)}>
        <Button variant="link" onClick={onBack}>
          <Text variant="text1">&lt; Back to Sign in</Text>
        </Button>
        {mode === 'initial' ? (
          <Stack gap="32px">
            <Text textAlign="center" variant="heading3">
              Reset Your Password
            </Text>
            <Text textAlign="center" variant="text3">
              Enter your email address below and we'll send you a link to reset your password.
            </Text>
            <Field {...fields.email} />
            {recaptchaEnabled && (
            <GoogleRecaptcha
              onErrorCallback={() => {
                setCaptchaValidationSuccess(false)
              }}
              onSuccessCallback={(result) => {
                setCaptchaResponse(result)
                setCaptchaValidationSuccess(true)
              }}
              onExpireCallback={() => {
                setCaptchaValidationSuccess(false)
              }}
            />
          )}
            <Button type="submit" isLoading={status === 'loading'} disabled={
              form.formState.isSubmitting || !prefLoaded || (recaptchaEnabled && !captchaValidationSuccess)
            }>
              Reset password
            </Button>
            <Box
              onClick={onClose}
            >
              <Text
                as={Link}
                href='/customer-support/contact-us'
                variant="text2"
                cursor="pointer"
                textDecoration="underline"
              >
                Something wrong?
              </Text>
          </Box>
          </Stack>
        ) : (
          <Stack>
            <Text textAlign="center" variant="heading3" color="accent1">
              Email sent
            </Text>
            <Text textAlign="center" variant="text3">
              You will soon receive the email for resetting your password.
            </Text>
            <Text textAlign="center" variant="text3">
              Remember to check your spam inbox folder.
            </Text>
            {!isMobileApp && (
              <BaseButton as={Link} to="/">
                <Text variant='text3' fontSize='inherit'>
                  Shop now
                </Text>
              </BaseButton>
            )}
          </Stack> 
        )}
      </Box>
    </Box>
  )
}

export const ResetPassword = observer(ResetPasswordContainer)
